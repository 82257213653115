<template>
  <div class="personalized-emails w100">
    <BaseModal>
      <p>
        {{ modal.text }}
      </p>
      <textarea ref="modalTextarea"
                @input="handleModalTextarea" />
      <p v-if="isFetching">
        Is fetching data ...
      </p>
      <div class="text-center">
        <button class="cancel-modal" @click="handleCancelModal">Cancel</button>
        <button @click="handleStepLaunch">
          {{ modal.buttonText }}
        </button>
      </div>
    </BaseModal>

    <h1 class="bold">
      Personalized email - staging
      <br>
      <span>
        Send personalized emails to RS users
      </span>
    </h1>

    <BaseInput name="email"
               label="Enter the e-mail of the user"
               :value="email"
               placeholder="email address"
               @input="handleEmail" />

    <div class="m-t-24 display: flex flex-wrap">
      <button v-for="({name, key}, index) in buttons"
              :key="index"
              :disabled="+step < (index + 1) || !email"
              class="purple-btn"
              @click="handleSubmit()">
        Launch {{ name }} email
      </button>
      <p v-if="isFetching">
        Is fetching data ...
      </p>
    </div>

    <div class="prompts-group">
      <details class="pointer" v-for="(item, index) in buttons"
               :key="index">
        <summary>Prompt for {{ item.name }} email &nbsp;</summary>
        <textarea
            :value="prompts[item.key]"
            @input="handlePrompt($event, item.key)"
            placeholder="job description"
        />
        <button class="purple-btn block"
                @click="handlePromptSave(item.key)">
          Save changes
        </button>
      </details>
    </div>

    <div class="results">
      <div v-for="(value, key) in results"
           :key="key"
           class="m-b-16">
        <h2>
          {{ key }}:
        </h2>
        <div class="m-t-8">
          <div class="bg-white rounded-lg shadow-lg p-6 font-mono text-sm">
            <div class="overflow-auto">
              <div class="ml-4">
                <JsonNode :value="value" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  import {useStore} from "vuex";
  import {computed, reactive, ref} from "vue";
  import BaseModal from "@/components/BaseModal.vue";
  import JsonNode from "@/components/JsonNode.vue";

  const {state, dispatch, commit} = useStore();
  dispatch('personalizedEmails/get_prompts');

  const modalTextarea = ref(null);
  const isFetching = computed(() => state.personalizedEmails.isFetching);
  const email = computed(() => state.personalizedEmails.email);
  const step = computed(() => state.personalizedEmails.step);
  const prompts = computed(() => state.personalizedEmails.prompts);
  const results = computed(() => state.personalizedEmails.results);
  const modal = computed(() => state.personalizedEmails.modal);
  const buttons = [
    {name: '1st', key: 'prompt1'},
    {name: '2nd', key: 'prompt2'},
    {name: '3rd', key: 'prompt3'},
    {name: '4th', key: 'prompt4'},
    {name: '5th', key: 'prompt5'},
  ]

  function handleEmail ({name, value}) {
    commit('personalizedEmails/SET_STATE', {key: 'email', data: value});
  }

  function handlePrompt (ev, key) {
    commit('personalizedEmails/UPDATE_PROMPT', {key, value: ev.target.value});
  }

  function handleSubmit () {
    switch (+step.value) {
      case 1:
        dispatch('personalizedEmails/get_first_result');
        break;
      case 2:
      case 3:
      case 4:
      case 5:
        break;
    }

    if ([2, 3, 4, 5].includes(+step.value)) {
      const modalText = +step.value === 3
          ? 'prompt3ModalStep1'
          : `prompt${+step.value}Modal`;

      commit('personalizedEmails/SET_MODAL_TEXT', modalText);

      if (+step.value === 2) {
        console.log('Platform Events:', state.personalizedEmails.platformEvents);
        commit('personalizedEmails/UPDATE_MODAL_TEXTAREA', state.personalizedEmails.platformEvents);
      }
      commit('SET_MODAL', true);
    }
  }

  function handleCancelModal () {
    commit('SET_MODAL', false);
  }

  function handleStepLaunch () {
    if (modal.value?.buttonText === 'Next') {
      modalTextarea.value.value = '';
      commit('personalizedEmails/SET_MODAL_TEXT', 'prompt3ModalStep2');
      return
    }
    dispatch(`personalizedEmails/get_results`);
  }

  function handleModalTextarea (ev) {
    commit('personalizedEmails/UPDATE_MODAL_TEXTAREA', ev.target.value);
  }

  function handlePromptSave (key) {
    dispatch('personalizedEmails/save_prompts', key)
  }
</script>

<style lang="scss">
  .personalized-emails {
    @media only screen and (min-width: 0) {
      .email {
        width: 450px;
        margin-bottom: 30px;
      }
      .purple-btn {
        width: 195px;
        height: 48px;
        margin-right: 30px;
      }
      .prompts-group {
        details {
          margin-top: 30px;
          summary {
            color: #ababab;
            font-size: 1.8rem;
            font-weight: 700;
          }
          textarea {
            width: 600px;
            height: 300px;
            margin-top: 30px;
          }
          &:first-child {
            textarea {
              width: 100%;
              height: 700px;
              margin-top: 30px;
            }
          }
          button {
            margin-top: 19px;
          }
        }
      }
      .results {
        width: 100%;
        //max-width: 68%;
        margin-top: 30px;
        h2 {
          font-weight: 400;
          color: #ababab;
          font-size: 1.8rem;
        }
      }
      .base-modal {
        > div {
          width: 424px;
          padding: 20px 30px;
        }
        p {
          margin-top: 0;
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
        }
        textarea {
          width: 100%;
          height: 308px;
          margin-top: 30px;
          display: block;
          border-radius: 4px;
          border: 0.5px solid #000000;
        }
        button {
          width: 75px;
          height: 46px;
          margin: 30px auto 0;
          border: none;
          background-color: #7c7c7c;
          color: #fff;
          font-size: 1.4rem;
          font-weight: 700;
          border-radius: 4px;
        }
        .cancel-modal {
          background-color: transparent;
          margin-right: 16px;
          color: #7c7c7c;
        }
      }
    }
  }
</style>