<template>
  <div v-if="isObject"
       class="json-node">
    {{ '{' }}
    <div v-for="(val, key, index) in value"
         :key="key"
         class="flex m-b-4">
      <b>{{ key }}</b>: &nbsp;
      <JsonNode :value="val" :is-last="index === Object.keys(value).length - 1" />
    </div>
    {{ '}' }}
  </div>

  <div v-else-if="isArray" class="ml-4">
    [
    <div v-for="(item, index) in value" :key="index" class="ml-4">
      <JsonNode :value="item" :is-last="index === value.length - 1" />
    </div>
    ]
  </div>

  <span v-else :class="typeStyle">
    {{ formattedValue }}
    <span v-if="!isLast">,</span>
  </span>
</template>

<script setup>
  import { computed } from 'vue'

  const props = defineProps({
    value: {
      type: [Object, Array, String, Number, Boolean],
      required: true
    },
    isLast: {
      type: Boolean,
      default: true
    }
  })

  const isObject = computed(() => typeof props.value === 'object' && !Array.isArray(props.value) && props.value !== null)
  const isArray = computed(() => Array.isArray(props.value))

  const typeStyle = computed(() => {
    if (typeof props.value === 'string') return 'text-green-600'
    if (typeof props.value === 'number') return 'text-blue-600'
    if (typeof props.value === 'boolean') return 'text-purple-600'
    if (props.value === null) return 'text-gray-600'
    return 'text-black'
  })

  const formattedValue = computed(() => {
    if (typeof props.value === 'string') return `"${props.value}"`
    return String(props.value)
  })
</script>

<style lang="scss">
  .json-node {
    b {
      font-weight: 500;
    }
  }
</style>