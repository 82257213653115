<template>
  <div v-if="isModal"
       class="base-modal fixed left0 top0 z9 flex-center wh100">
    <div>
      <slot />
    </div>
  </div>
</template>

<script setup>
  import {useStore} from "vuex";
  import {computed} from "vue";

  const {state} = useStore();
  const isModal = computed(() => state.isModal);
</script>

<style lang="scss">
  .base-modal {
    @media only screen and (min-width: 0) {
      background-color: rgba(0, 0, 0, 0.7);
      > div {
        background-color: white;
      }
    }
  }
</style>